type Page = {
  name: string;
  path: RegExp;
};

// WithHandlerで該当するpathに対してNginxにリダイレクトさせる
// フロー: リリース前のページpathをここに定義する -> Next.jsでの開発が終わったらページpathをここから外す -> Nginxの設定を変更する
export const UnReleasedPages: Page[] = [];

export const isReleasedFollowRanking = (): boolean => {
  return !UnReleasedPages.some((page) => page.name === 'follow_ranking');
};

export const isReleasedGsNextConcurrentRegistration = (): boolean => {
  return false;
};
